<template>
  <div>
    <!-- 头部 -->
    <!-- <van-cell center is-link to="#" value="切换就诊卡"> -->
    <van-cell center class="van-cell_herder">
      <!-- 使用 title 插槽来自定义标题 -->
      <template slot="title">
        <span class="custom-title">{{ userName }}</span>
      </template>
      <template slot="label">
        <span>{{ cardNo }}</span>
      </template>
      <template slot="default">
        <!-- <van-button type="default" text="显示遮罩层" icon="qiehuan" icon-color="#57c4b7" @click="showOverlay = true" /> -->
        <span @click="onqiehuan">切换就诊卡</span>
      </template>
      <van-icon
        slot="right-icon"
        class="iconfont"
        @click="onqiehuan"
        class-prefix="icon"
        name="qiehuan"
        color="#57c4b7"
        style="margin-left: 2%"
      />
    </van-cell>
    <van-cell>
      <template slot="title">
        <!-- <span style="color:red;">*福贡县人民医院温馨提示：微信医院挂号可在微信医院上进行退号退费，其他渠道挂号的请到收费窗口进行处理</span> -->
        <!-- <span style="color:red;">*江城县人民医院温馨提示：微信医院挂号可在微信医院上进行退号退费，其他渠道挂号的请到收费窗口进行处理</span> -->
        <!-- <span style="color: red"
          >*澜沧县第一人民医院温馨提示：仅能查询3个月内的记录，如需查询更早以前请到窗口查询。微信医院挂号可在微信医院上进行退号退费，其他渠道挂号的请到收费窗口进行处理。</span
        > -->
        <span style="color: red"
          >*福贡县人民医院温馨提示：仅能查询3个月内的记录，如需查询更早以前请到窗口查询。微信医院挂号可在微信医院上进行退号退费，其他渠道挂号的请到收费窗口进行处理。</span
        >
      </template>
    </van-cell>
    <!-- <van-popup v-model="showOverlay" position="top"  :style="{ width: '100%',top: header_height+'px'}">
       <van-cell-group>
        <van-cell :title="list[0].message_ERname" @click="cahngeRegistCell">{{list[0].message_Medicalcard}}</van-cell>
        <van-cell :title="list[0].message_ERname">{{list[0].message_Medicalcard}}</van-cell>
    </van-cell-group> -->
    <!-- </van-popup> -->
    <!-- <van-overlay :show="showOverlay" @click="showOverlay = false" /> -->

    <!-- 中间 -->
    <div class="regilist_center">
      <van-cell-group>
        <div style="width: 100%">
          <van-cell center>
            <template slot="icon">
              <van-dropdown-menu>
                <van-dropdown-item
                  v-model="value1"
                  :options="option1"
                  @change="medicalDrop"
                />
              </van-dropdown-menu>
            </template>
            <template slot="title">
              <div style="text-align: center">
                <span @click="showTime = true">{{ date }}</span>
                <!-- <van-calendar v-model="showTime" type="range" @confirm="onConfirm" :selected="startdate"  :min-date="minDate" :max-date="maxDate"/> -->
                <van-calendar
                  v-model="showTime"
                  type="range"
                  @confirm="onConfirm"
                  :defaultDate="defaultDate"
                  :min-date="minDate"
                  :max-date="maxDate"
                  color="#57c4b7"
                />
                <!-- <van-calendar v-model="showTime" type="range" /> -->
              </div>
            </template>
            <template slot="right-icon">
              <div style="margin-right: 3%">
                <van-dropdown-menu active-color="#1989fa">
                  <van-dropdown-item
                    v-model="value2"
                    :options="option2"
                    @change="sortDrop"
                  />
                </van-dropdown-menu>
              </div>
            </template>
          </van-cell>
        </div>
      </van-cell-group>
    </div>

    <!-- 列表 -->
    <div>
      <van-cell-group v-for="(Ditem, index) in lists" :key="index">
        <van-list v-bind="Ditem">
          <div style="width: 100%">
            <van-cell
              center
              @click="onclickDetail(Ditem.isSee, Ditem.clinicNO)"
            >
              <template slot="icon">
                <img
                  class="GHJL_list_header_img"
                  src="@/assets/imagel/header.png"
                  alt=""
                />
              </template>
              <template slot="title">
                <span>{{ Ditem.doctName }}</span>
                <span style="font-size: 12px; color: #57c4b7"> | </span>
                <span style="font-size: 12px; color: #57c4b7">{{
                  Ditem.deptName
                }}</span>
                <span style="font-size: 12px; color: #57c4b7"> | </span>
                <span class="lable_cell_color_green">
                  {{ Ditem.stateDescript }}
                </span>
                <!-- <span v-if="Ditem.isSee" class="lable_cell_color_green"
                    >已就诊</span
                  >
                  <span v-else class="lable_cell_color_red">待就诊</span> -->
              </template>
              <template slot="label">
                <span style="font-size: 12px; color: #999999">
                  {{ Ditem.regType }}
                  <!-- 诊查费 -->
                </span>
              </template>
              <template slot="right-icon">
                <div
                  v-if="Ditem.isSee == 1"
                  style="font-size: 16px; color: #57c4b7"
                >
                  已就诊
                  <!-- <div style="display: block; text-align: center">
                      <span style="font-size: 24px; color: #57c4b7">{{
                        Ditem.scheduleId
                      }}</span>
                      <span style="display: block; font-size: 12px"
                        >预约序号</span
                      >
                    </div> -->
                </div>
                <div v-else style="font-size: 16px; color: red">未就诊</div>
              </template>
            </van-cell>

            <van-cell>
              <template slot="title">
                <span v-if="Ditem.seeTime !== ''"
                  >就诊时间:{{ Ditem.seeTime }}</span
                >
                <span v-else>预约时间:{{ Ditem.regTime }}</span>
              </template>
              <template slot="right-icon">
                <!-- Ditem.state === 1 ||  Ditem.state === 0 ||  Ditem.state === 3 ||  isSeeList[Ditem.regNo] -->
                <van-button
                  :class="[Ditem.seeTime !== '' ? 'right_icon_cell' : '']"
                  size="small"
                  @click="onclickesc(Ditem.clinicNO)"
                  >取消挂号</van-button
                >
              </template>
            </van-cell>
          </div>
        </van-list>
      </van-cell-group>
    </div>

    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>
<script>
import { Dialog, Toast } from "vant";
import ajax from "../../lib/ajax";
import store from "../../store";
// const OperatorId = "000000";
let listss = [];
var nowDate = new Date();
export default {
  data() {
    return {
      // header_height: 0,
      // showOverlay:false,
      date: "",
      startTime: "",
      endTime: "",
      startdate: nowDate,
      minDate: new Date(new Date().setDate(new Date().getDate() - 83)),
      maxDate: new Date(new Date().setDate(new Date().getDate() + 7)),
      value1: 0,
      showTime: false,
      defaultDate: [],
      lists: [],
      list: [],
      userName: "",
      cardNo: "",
      currentDate: new Date(),
      onclickChangecard: false,
      value2: 0,
      iserweima: false,
      isSeeList: {},
      // message_ERname: "张三",
      // message_Medicalcard: '555555551', //就诊卡号
      option1: [
        {
          text: "全部",
          value: 0,
        },
        {
          text: "已支付",
          value: 1,
        },
        {
          text: "已退款",
          value: 2,
        },
      ],
      option2: [
        {
          text: "默认排序",
          value: 0,
        },
        {
          text: "就诊排序",
          value: 1,
        },
        {
          text: "时间排序",
          value: 2,
        },
      ],
    };
  },
  created() {
    this.userName = store.getters["store/userName"];
    this.cardNo = store.getters["store/userId"]; //声明就诊卡号

    //初始化时间
    const lastDate = new Date(new Date().setDate(new Date().getDate() + 7));
    const date = [new Date(), lastDate];

    this.defaultDate = date;

    // 初始化数据
    this.onConfirm(date);
    // this.getRegL();
    // this.medicalDrop();
    //初始化lists[]
    //  this.medicalDrop(0);
  },
  methods: {
    getRegL() {
      // 异步更新数据
      // setTimeout(() => {
      //   for (let i = 0; i < 0; i++) {
      //     this.lists.push(this.lists.length);
      //   }
      //   // 加载状态结束
      //   this.loading = false;

      //   // 数据全部加载完成
      //   if (this.lists.length == this.lists.length) {
      //     this.finished = true;
      //   }
      // }, 500);
      let userId = store.getters["store/userId"];
      // let idcard = store.getters["store/idcard"];
      // alert("病历号："+patientid);
      // let patientid = store.getters["store/patientid"]; //声明病历号
      // this.userName = "王德刚";
      ajax
        .post("/Api/RegistrationService/QueryRegistRecords", {
          // userID: userId,
          userID: userId,
          // userName:this.userName,
          // state:[2,3],
          startDate: this.startTime + " 00:00:00",
          endDate: this.endTime + " 23:59:59",
          // machineName: "wechat",
        })
        .then((rep) => {
          // this.lists = [];
          listss = rep.data.result.regRecordLists;
          console.log(listss);
          // let regs = [];
          // for (let i = 0; i < listss.length; i++) {
          //   listss[i].scheduleDate = listss[i].scheduleDate.substring(0, 10);
          //   regs.push(listss[i].regNo);
          //   if(listss[i].drId == ''){
          //     listss[i].regLevelName = '普通号'
          //     listss[i].drName = '门诊医生|医师'
          //   }else{
          //      listss[i].regLevelName = '专家号'
          //   }
          //   let name = listss[i].drName.split("|");
          //   listss[i].docname = name[0];
          // }
          // if(this.value1== 1){
          //   listss = listss.filter((item) => item.state == 2);
          //   // console(2);
          // }
          // if(this.value1 == 2){
          //   listss = listss.filter((item) => item.state == 3);
          // }
          this.lists = listss;
          if (this.lists !== null && this.lists.length > 0) {
            // ajax
            //   .post("/Api/RegistrationService/IsSeeDoctor", {
            //     idCard: idcard,
            //     regNos: regs,
            //   })
            //   .then((res) => {
            //     console.log(res.data.result);
            //     this.isSeeList = res.data.result;
            //   })
            //   .catch((err) => {
            //     Toast(err.message);
            //   });
            // this.medicalDrop();
            this.list = listss;
          } else {
            Toast(rep.data.result.errorMsg);
          }
        })
        .catch((err) => {
          // console.log(err);
          Toast(err.message);
        });
    },
    // initDate(list){
    //   this.list = list;
    //   // console.log(this.list);
    // },

    //全部,已就诊,未就诊下拉菜单改变事假
    medicalDrop() {
      this.selectDropTime();
      this.sortDrop(this.value2);
    },
    //默认排序下拉菜单改变事件
    sortDrop(value) {
      if (value == 0) {
        console.log("");
      } else if (value == 1) {
        // this.selectDropTime();
        this.lists.sort(this.sortDepNumber);
      } else if (value == 2) {
        // console.log(value);
        this.lists.sort(this.sortTime);
      } else {
        this.$toast("出现未知错误!!!");
      }
    },
    //就诊排序
    sortDepNumber(obj1, obj2) {
      return obj1.scheduleId - obj2.scheduleId;
    },
    //时间排序
    sortTime(obj1, obj2) {
      return obj2.visitTime - obj1.visitTime;
    },
    //切换时间格式
    formatDate(date) {
      return `${
        date.getMonth() >= 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }${date.getDate() >= 10 ? "-" + date.getDate() : "-0" + date.getDate()}`;
    },
    //日历改变事件
    onConfirm(date) {
      // console.log(date);
      const [start, end] = date;
      this.showTime = false;
      this.startTime =
        `${start.getFullYear()}` + "-" + `${this.formatDate(start)}`;
      this.endTime = `${end.getFullYear()}` + "-" + `${this.formatDate(end)}`;
      this.date = `${
        start.getMonth() >= 9
          ? start.getMonth() + 1
          : "0" + (start.getMonth() + 1)
      }${start.getDate()>= 10 ? "-" + start.getDate() : "-0" + start.getDate()}至${
        end.getMonth() >= 9 ? end.getMonth() + 1 : "0" + (end.getMonth() + 1)
      }${end.getDate()>= 10 ? "-" + end.getDate() : "-0" + end.getDate()}`;
      this.medicalDrop();
      // this.sortDrop(this.value2);
      // return [start,end];
    },
    //下拉全部菜单筛选、时间筛选、排序
    async selectDropTime() {
      // console.log(1234565);
      this.getRegL();

      // if (this.value1 == 0) {
      //   for (let i = 0; i < listss.length; i++) {
      //     let index = listss[i];
      //     this.lists.push(index);
      //   }
      // } else if (this.value1 == 1) {
      //   for (let i = 0; i < listss.length; i++) {
      //     if (listss[i].state == 2) {
      //       let index = listss[i];
      //       this.lists.push(index);
      //     }
      //   }
      // } else if (this.value1 == 2) {
      //   for (let i = 0; i < listss.length; i++) {
      //     if (listss[i].state == 3) {
      //       let index = listss[i];
      //       this.lists.push(index);
      //     }
      //   }
      // } else {
      //   this.$toast("出现未知错误!!!");
      // }
      // console.log(listss);
      // console.log(this.list);
      // this.lists = [];
      // if (this.value1 == 0) {
      //   for (let i = 0; i < listss.length; i++) {
      //     if (
      //       this.DateContrast(this.startTime, listss[i].visitTime) &&
      //       this.DateContrast(listss[i].visitTime, this.endTime)
      //     ) {
      //       let index = listss[i];
      //       this.lists.push(index);
      //     }
      //   }
      // } else if (this.value1 == 1) {
      //   for (let i = 0; i < listss.length; i++) {
      //     if (
      //       !listss[i].regStatus &&
      //       this.DateContrast(this.startTime, listss[i].bookTime) &&
      //       this.DateContrast(listss[i].bookTime, this.endTime)
      //     ) {
      //       let index = listss[i];
      //       this.lists.push(index);
      //     }
      //   }
      // } else if (this.value1 == 2) {
      //   for (let i = 0; i < listss.length; i++) {
      //     if (
      //       listss[i].isSee &&
      //       this.DateContrast(this.startTime, listss[i].visitTime) &&
      //       this.DateContrast(listss[i].visitTime, this.endTime)
      //     ) {
      //       let index = listss[i];
      //       this.lists.push(index);
      //     }
      //   }
      // } else {
      //   Toast("出现未知错误!!!");
      // }
    },
    //时间比较
    DateContrast(start, end, flags) {
      const starttime = new Date(
        start.substring(0, 4),
        start.substring(4, 6),
        start.substring(6, 8)
      );
      const starttimes = starttime.getTime();
      const endTime = new Date(
        end.substring(0, 4),
        end.substring(4, 6),
        end.substring(6, 8)
      );
      const endTimes = endTime.getTime();
      if (endTimes >= starttimes) {
        flags = true;
        return flags;
      } else {
        flags = false;
        return flags;
      }
    },
    onclickesc(regNo) {
      // let openid = store.getters["wechat/openid"];
      // let Name = store.getters["store/userName"];
      // let No = store.getters['store/cardNo'];//声明就诊卡号
      Dialog.confirm({
        title: "温馨提示",
        message: "确定取消挂号",
      })
        .then(async () => {
          console.log(regNo);
          // if (payWay == "微信") {
          await ajax
            .get("/Api/RegistrationService/RegRefund?input=" + regNo)
            .then(async (res) => {
              console.log(res.data.result);
              if (res.data.result === 1) {
                Toast("您已成功退号，退款正在受理中，请注意查收");
                this.getRegL();
              } else if (res.data.result === -1) {
                Toast("订单不存在");
              } else if (res.data.result === -2) {
                Toast("his返回失败");
              } else {
                Toast("申请退款失败，请到窗口详询");
              }
            })
            .catch((err) => {
              console.log(err.message);
              Toast(err.message);
            });
        })
        .catch(() => {
          Toast("您取消了取消挂号!");
        });
    },
    onclickDetail(isSee, regNo) {
      window.sessionStorage.setItem("regSTime", this.startTime);
      window.sessionStorage.setItem("regETime", this.endTime);
      // console.log(regStatus);
      // if (isSee || state == 1 || state == 3 ||state == 0) {
      if (isSee == 1) {
        // alert(state);
        store.commit("store/setRegNo", regNo);
        this.$router
          .replace({
            path: "/QRegIT",
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        store.commit("store/setRegNo", regNo);
        this.$router
          .replace({
            path: "/QRegIF",
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onqiehuan() {
      this.$router
        .replace({
          path: "/QRCardL",
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.regilist_center {
  .van-cell-group {
    // display: flex;
    .van-cell {
      margin: 0;
      padding: 0;
    }
  }
}
.right_icon_cell {
  display: none;
}
.lable_cell_color_green {
  font-size: 12px;
  color: #57c4b7;
}
.lable_cell_color_red {
  font-size: 12px;
  color: red;
}
.GHJL_list_header_img {
  height: 70px;
  padding: 4px;
}
</style>
